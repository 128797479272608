import {
  container,
  description,
  primaryColor,
  section,
  title,
} from "assets/jss/material-kit-pro-react.jsx"
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.jsx"

const imgRaised = imagesStyles.imgRaised
const rounded = imagesStyles.imgRounded
const imgFluid = imagesStyles.imgFluid

const chiliCookOffPublicTastingStyle = (theme) => ({
  aboutButton: {
    margin: "1rem",
    lineHeight: "18px",
  },
  consultationFormButton: {
    float: "left",
    "& span": {
      lineHeight: "normal",
    },
    [theme.breakpoints.down("sm")]: {
      float: "none",
      marginBottom: "2rem",
    },
  },
  aboutIcon: {
    maxWidth: "100%",
  },
  aboutItem: {
    "@media (max-width: 599px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "83.333333%",
      flexBasis: "83.333333%",
    },
    "@media (max-width: 800px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  aboutTitle: {
    ...title,
    textAlign: "left",
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  aboutImage: {
    ...imgRaised,
    ...imgFluid,
    ...rounded,
    maxHeight: "200px",
  },
  aboutImageWrapper: {
    maxHeight: "200px",
    margin: "1rem",
  },
  title: {
    ...title,
    marginTop: "0",
  },
  container,
  description,
  section: {
    ...section,
    padding: "80px 0",
    textAlign: "center",
  },
  tastingIcon: {
    color: primaryColor[0],
    fontSize: "2.25rem",
    marginBottom: "1rem",
  },
  publicTastingInfo: {
    ...description,
    textAlign: "left",
    marginBottom: "2rem",
    "& li": {
      margin: "1rem 0",
    },
  },
  servicesAboutListTitle: {
    ...description,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  servicesDescription: {
    ...description,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
})

export default chiliCookOffPublicTastingStyle
