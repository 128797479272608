import { faUtensilsAlt } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page styles
import chiliCookOffPublicTastingStyle from "assets/jss/material-kit-pro-react/views/chiliCookOffPageSections/chiliCookOffPublicTastingStyle.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Fade from "react-reveal/Fade"

const useStyles = makeStyles(chiliCookOffPublicTastingStyle)

export default function ChiliCookOffJudging() {
  const {
    publicTastingImage1,
    publicTastingImage2,
    publicTastingImage3,
  } = useStaticQuery(graphql`
    query getChiliCookOffPublicTastingImages {
      publicTastingImage1: file(
        relativePath: { eq: "chili-cook-off/chili-cook-off-13.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      publicTastingImage2: file(
        relativePath: { eq: "chili-cook-off/chili-cook-off-2.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      publicTastingImage3: file(
        relativePath: { eq: "chili-cook-off/chili-cook-off-14.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10} className={classes.aboutItem}>
            <h2 className={classes.title}>Public Tasting</h2>
            <FontAwesomeIcon
              icon={faUtensilsAlt}
              className={classes.tastingIcon}
            />
            <p className={classes.description}>
              <b>
                Public tasting of the cooks’ chili is one of the most popular
                events at Chilimania. Please note the procedure:
              </b>
            </p>
            <ul className={classes.publicTastingInfo}>
              <li>
                At 3 PM, sampler cup baggies (containing a small sample cup,
                spoon and ballot) will be handed out. As the public obtains
                their sample cups, public tasting begins. Cooks will ladle out
                small samples of chili into the individual sampler cups as the
                crowd moves from booth to booth.
              </li>
              <li>
                The public will be able to vote for their favorite chili by
                marking the booth number on the ballot included in their packet
                and placing the ballot in a bin located on the main stage.
                Counting of the ballots is how the People’s Choice is awarded.
              </li>
              <li>
                We use red flags on the individual booths to indicate the
                availability of chili for public tasting. When you run out of
                chili, please take down your flag.
              </li>
              <li>
                Cooks are asked to have a Scholarship Fund donation bucket at
                their booth. Donations made to these buckets will have no effect
                on the People’s Choice award. However, since Chilimania is about
                raising funds for scholarships, any help you can give us in
                obtaining donations is appreciated.
              </li>
              <li>
                <b>
                  A $50 gift card will be awarded to the booth that collects the
                  most money for our scholarship fund.
                </b>
              </li>
            </ul>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem md={4} sm={4}>
            <div className={classes.aboutImageWrapper}>
              <Fade delay={200}>
                <GatsbyImage
                  image={publicTastingImage1.childImageSharp.gatsbyImageData}
                  className={classes.aboutImage}
                  alt="Public Tasting Image 1"
                />
              </Fade>
            </div>
          </GridItem>
          <GridItem md={4} sm={4}>
            <div className={classes.aboutImageWrapper}>
              <Fade delay={300}>
                <GatsbyImage
                  image={publicTastingImage2.childImageSharp.gatsbyImageData}
                  className={classes.aboutImage}
                  alt="Public Tasting Image 2"
                />
              </Fade>
            </div>
          </GridItem>
          <GridItem md={4} sm={4}>
            <div className={classes.aboutImageWrapper}>
              <Fade delay={400}>
                <GatsbyImage
                  image={publicTastingImage3.childImageSharp.gatsbyImageData}
                  className={classes.aboutImage}
                  alt="Public Tasting Image 3"
                />
              </Fade>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
